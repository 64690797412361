<i18n>
ru:
  corpus: Строение/корпус
  house: Дом
  street: Улица
  incorrectGeocode: Улица вышла за зону доставки или не найдена. Попробуйте найти её на карте или обратитесь в поддержку
  incorrectStreet: Введена некорректная улица
  incorrectHouse: Введён некорректный дом
ua:
  corpus: Будова/корпус
  house: Будинок
  street: Вулиця
  incorrectGeocode: Вулиця вийшла за зону доставки або не знайдена. Спробуйте знайти її на карті або зверніться в підтримку
  incorrectStreet: Введена некоректна вулиця
  incorrectHouse: Введено некоректний будинок
us:
  corpus: Building/corpus
  house: House
  street: Street
  incorrectGeocode: The street went beyond the delivery area or was not found. Try to find it on the map or contact support
  incorrectStreet: Incorrect street entered
  incorrectHouse: Incorrect house entered
</i18n>

<template>
  <div
    v-if="ymaps3Loaded"
    class="v-row v-address-select-where-to-custom"
  >
    <div
      v-if="title"
      class="v-col-12 v-address-or-city-title"
      v-html="sanitize(title)"
    />
    <div
      :class="[
        appConfig.VueSettingsPreRun.CorpusHidden
          ? 'v-col-12 v-col-sm-6 v-col-md-8'
          : 'v-col-12 v-col-sm-6 v-col-md-5'
      ]"
    >
      <common-typeahead-input
        required
        v-form-validator="{
          Form: form,
          Value: isUserStreetCorrect ? '#' : '',
          Required: true,
          RequiredText: translate('yandexMapsAutocomplete.incorrectStreet'),
          Validations: ['required', 'lengthMinMax'],
          MinLength: 1,
          MaxLength: 60
        }"
        :input="userStreet"
        :label="translate('yandexMapsAutocomplete.street')"
        :min-chars="appConfig.VueSettingsPreRun.StreetListAutocompleteMinChars"
        :user-input-changed="userInputStreetChanged"
        :zero-results="translate('yandexMapsAutocomplete.incorrectStreet')"
        data-test-id="yandex-street"
      />
      <div
        v-if="!isUserStreetGeocoded"
        class="v-error-color v-mb-sm"
        v-html="translate('yandexMapsAutocomplete.incorrectGeocode')"
      />
    </div>

    <div
      :class="[
        appConfig.VueSettingsPreRun.CorpusHidden
          ? 'v-col-12 v-col-sm-6 v-col-md-4'
          : 'v-col-12 v-col-sm-6 v-col-md-3'
      ]"
    >
      <common-typeahead-input
        disable-subtitle
        required
        v-form-validator="{
          Form: form,
          Value: isUserHouseCorrect ? '#' : '',
          Required: true,
          RequiredText: translate('yandexMapsAutocomplete.incorrectHouse'),
          Validations: ['required', 'lengthMinMax'],
          MinLength: 1,
          MaxLength: 10
        }"
        :disabled="stringIsNullOrWhitespace(userStreet)"
        :input="userHouse"
        :label="translate('yandexMapsAutocomplete.house')"
        :min-chars="0"
        :user-input-changed="userInputHouseChanged"
        :zero-results="translate('yandexMapsAutocomplete.incorrectHouse')"
        data-test-id="yandex-house"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.CorpusHidden"
      class="v-col-12 v-col-sm-6 v-col-md-4"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: form,
          Value: corpus,
          Required: appConfig.VueSettingsPreRun.CorpusRequired,
          Validations: ['noSpace', 'length'],
          MaxLength: 10
        }"
        :disabled="stringIsNullOrWhitespace(userStreet)"
        :label="translate('yandexMapsAutocomplete.corpus')"
        :required="appConfig.VueSettingsPreRun.CorpusRequired"
        data-test-id="order-user-corpus"
        v-model:text="corpus"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TypeaheadOptions } from '~types/common'
import type { Geocode } from '~types/mapsStore'
import type { HasValidatorForm } from '~types/props'

import { type GUID, useCommon, vFormValidator, VInput } from '@arora/common'

withDefaults(
  defineProps<
    HasValidatorForm & {
      title?: string
    }
  >(),
  {
    title: '',
    form: 'cart'
  }
)

const appConfig = useAppConfig()
const addressStore = useAddressStore()
const clientStore = useClientStore()
const mapsStore = useMapsStore()
const popupStore = usePopupStore()

const { translate, sanitize } = useI18nSanitized()
const { debounce, stringIsNullOrWhitespace } = useCommon()
const { fromPopup } = useInstance()

const ymaps3Loaded = computed<boolean>(
  () => mapsStore.YandexMaps.state === 'success' && addressStore.CityOrganisation?.data !== null
)

const userStreet = ref<string>(clientStore.ClientState.data?.SelectedDeliveryAddress?.Street ?? '')
const isUserStreetCorrect = ref<boolean>(false)
const isUserStreetGeocoded = ref<boolean>(true)

const userHouse = ref<string>(clientStore.ClientState.data?.SelectedDeliveryAddress?.House ?? '')
const isUserHouseCorrect = ref<boolean>(false)

const corpus = computed<string>({
  get: () => {
    return clientStore.ClientState.data?.SelectedDeliveryAddress?.Corpus ?? ''
  },
  set: (value) => {
    debounce(() =>
      clientStore.updateDeliveryAddressSingle({
        corpus: value ?? ''
      })
    )
  }
})

async function userInputStreetChanged(value: string): Promise<TypeaheadOptions<null>[]> {
  isUserStreetCorrect.value = false

  userStreet.value = value

  if (stringIsNullOrWhitespace(value)) {
    clientStore.updateDeliveryAddressSingle({
      street: ''
    })

    return []
  }

  if (value.length < appConfig.VueSettingsPreRun.StreetListAutocompleteMinChars) {
    return []
  }

  const result: TypeaheadOptions<null>[] = []

  if (appConfig.VueSettingsPreRun.YaMapsUseGeocodeInsteadOfSuggest) {
    const geocode: Geocode = await mapsStore.geocodeYmap(value, 'street')

    for (const member of geocode.response.GeoObjectCollection.featureMember.filter(
      (mem) =>
        mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'airport' ||
        mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'district' ||
        mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'house' ||
        mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'railway_station' ||
        mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'street'
    )) {
      result.push({
        ID: member.GeoObject.name as GUID,
        title: member.GeoObject.name,
        subtitle: member.GeoObject.description,
        item: null,
        onClick: () => {
          isUserStreetGeocoded.value = true

          const coords = member.GeoObject.Point.pos.split(' ')
          if (
            !mapsStore.updateDeliveryCoordsWithGeocode(
              [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])],
              member.GeoObject
            )
          ) {
            if (!fromPopup.value) {
              popupStore.showError(translate('yandexMapsAutocomplete.incorrectGeocode'))
            }
            isUserStreetGeocoded.value = false
          }
        }
      })
    }
  } else {
    const suggestResponseItems = await mapsStore.geocodeYMapSuggest(value)

    for (const suggestResponseItem of suggestResponseItems) {
      result.push({
        ID: (suggestResponseItem.title?.text ?? '') as GUID,
        title: suggestResponseItem.title?.text,
        subtitle: suggestResponseItem.subtitle?.text,
        item: null,
        onClick: () => {
          isUserStreetGeocoded.value = true

          mapsStore
            .geocodeYmap(
              `${suggestResponseItem.subtitle?.text ?? ''} ${suggestResponseItem.value}`,
              'street'
            )
            .then((geocode: Geocode) => {
              if (geocode.response.GeoObjectCollection.featureMember.length === 0) {
                console.error('no results in geocode', geocode)

                if (!fromPopup.value) {
                  popupStore.showError(translate('yandexMapsAutocomplete.incorrectGeocode'))
                }
                isUserStreetGeocoded.value = false

                return
              }

              const member = geocode.response.GeoObjectCollection.featureMember.find(
                (mem) =>
                  mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'airport' ||
                  mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'district' ||
                  mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'house' ||
                  mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'railway_station' ||
                  mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'street'
              )
              const coords = member?.GeoObject.Point.pos.split(' ')
              if (
                !member ||
                !coords ||
                !mapsStore.updateDeliveryCoordsWithGeocode(
                  [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])],
                  member.GeoObject
                )
              ) {
                if (!fromPopup.value) {
                  popupStore.showError(translate('yandexMapsAutocomplete.incorrectGeocode'))
                }
                isUserStreetGeocoded.value = false
              }
            })
            .then(() => {
              userHouse.value = clientStore.ClientState.data?.SelectedDeliveryAddress?.House ?? ''
              isUserHouseCorrect.value = !stringIsNullOrWhitespace(userHouse.value)
            })
        }
      })
    }
  }

  return result
}

async function userInputHouseChanged(value: string): Promise<TypeaheadOptions<null>[]> {
  isUserHouseCorrect.value = false
  userHouse.value = value

  if (stringIsNullOrWhitespace(value)) {
    clientStore.updateDeliveryAddressSingle({
      house: ''
    })

    return []
  }

  const street = clientStore.ClientState.data?.SelectedDeliveryAddress?.Street ?? ''
  const result: TypeaheadOptions<null>[] = []
  const geocode = await mapsStore.geocodeYmap(`${street} ${value}`, 'house')

  for (const member of geocode.response.GeoObjectCollection.featureMember) {
    const house = member.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(
      (x) => x.kind === 'house'
    )

    if (house)
      result.push({
        ID: `${house.name}-${house.kind}` as GUID,
        title: house.name,
        subtitle: '',
        item: null,
        onClick: () => {
          const coords = member.GeoObject.Point.pos.split(' ')
          mapsStore.updateDeliveryCoordsWithGeocode(
            [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])],
            member.GeoObject
          )
        }
      })
  }

  return result
}

watch(
  () => clientStore.ClientState.data?.SelectedDeliveryAddress,
  (newValue) => {
    if (newValue) {
      userStreet.value = newValue.Street
      userHouse.value = newValue.House

      if (newValue.Street) {
        isUserStreetCorrect.value = true
        isUserStreetGeocoded.value = true
      }
      if (newValue.House) {
        isUserHouseCorrect.value = true
      }
    }
  },
  { deep: true }
)

onMounted(async () => {
  if (clientStore.ClientState.data?.SelectedDeliveryAddress?.Street) {
    isUserStreetCorrect.value = true
    isUserStreetGeocoded.value = true
  }

  if (clientStore.ClientState.data?.SelectedDeliveryAddress?.House) {
    isUserHouseCorrect.value = true
  }

  if (mapsStore.YandexMaps.state !== 'success') {
    await mapsStore.initYandexMaps()
  }
})
</script>

<style lang="scss">
.suggestions-container-streets {
  position: relative;
  float: left;
  width: 400px;
}

.v-address-or-city-title {
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
